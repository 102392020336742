<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '' }">账号管理</el-breadcrumb-item>
      <el-breadcrumb-item>账号设置</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-button
        type="primary"
        size="small"
        style="background: #4781d9; border: 0"
        @click="add"
        >新增+</el-button
      >
      <el-form
        ref="form"
        :model="searchForm"
        label-width="100px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="账号">
          <el-input v-model="searchForm.chain_account"/>
        </el-form-item>
        <el-form-item label="角色">
          <el-select v-model="searchForm.chain_job" placeholder="请选择">
            <el-option label="运营" :value="2"></el-option>
            <el-option label="财务" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="searchForm.chain_status" placeholder="请选择">
            <el-option label="使用" :value="1"></el-option>
            <el-option label="禁用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表单 -->
     <el-dialog
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="accountVisible"
      :title="accountVisibleTitle"
      width="500px"
    >
      <el-form ref="accountForm" :inline="true" :model="accountForm" size="small" :rules="rules" label-width="100px">
        <el-form-item label="名称" prop="chain_name">
          <el-input v-model="accountForm.chain_name" placeholder="请输入" style="width:300px" />
        </el-form-item>
        <el-form-item label="手机号" prop="chain_phone">
          <el-input v-model="accountForm.chain_phone" placeholder="请输入" style="width:300px" />
        </el-form-item>
        <el-form-item label="账号" prop="chain_account">
          <el-input v-model="accountForm.chain_account" placeholder="请输入" style="width:300px" />
        </el-form-item>
        <el-form-item label="密码" prop="chain_password" :rules="[{ required: !accountForm.id, message: '请输入密码' }]">
          <el-input v-model="accountForm.chain_password" placeholder="请输入" style="width:300px" />
        </el-form-item>
        <el-form-item label="角色" prop="chain_job">
          <el-select v-model="accountForm.chain_job" placeholder="请选择" style="width:300px">
            <el-option label="运营" :value="2"></el-option>
            <el-option label="财务" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="区域" prop="chain_region_ids" v-if="accountForm.chain_job==2&&chainRegionList.length>0">
          <el-select
            v-model="accountForm.chain_region_ids"
            placeholder="请选择"
            multiple
            style="width:300px"
          >
            <el-option
              v-for="(item, index) in chainRegionList"
              :key="index"
              :label="item.region_name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="chain_status">
          <el-select v-model="accountForm.chain_status" placeholder="请选择" style="width:300px">
            <el-option label="使用" :value="1"></el-option>
            <el-option label="禁用" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="accountVisible=false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitaccountForm">确认</el-button>
      </div>
    </el-dialog>
    <!-- 表格 -->
    <div class="data-table">
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          type="index"
          :header-cell-style="{ background: '#CD0550', color: '#fff' }"
        >
          <el-table-column prop="id" label="ID"/>
          <el-table-column prop="chain_name" label="名称"/>
          <el-table-column prop="chain_phone" label="手机号"/>
          <el-table-column prop="chain_account" label="登录账号"/>
          <el-table-column prop="" label="角色">
            <template slot-scope="scope">
              <span>
                {{scope.row.chain_job==1?'管理员':scope.row.chain_job==2?'运营':'财务'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="area" label="区域"/>
          <el-table-column prop="bill_status" label="状态">
            <template slot-scope="scope">
              <span>
                {{scope.row.chain_status==0?'禁用':'使用'}}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div style="position: relative; display: inline">
                <i
                  style="color: #3ca6f0"
                  @click="edit(scope.row)"
                  class="iconfont icon-bianji"
                >
                </i>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrl, httpUrl } from "@/utils/http.js"
import {getChainUserAccountList_api,createChainUserAccount_api,editChainUserAccount_api,getChainRegion_api} from "@/api/goods.js"
import axios from "axios"
import local from "@/utils/local.js"
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      let regPone = null
      const mobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/ // 最新手机正则
      const tel = /^(0\d{2,3}-){0,1}\d{7,8}$/ // 座机
      if (value.charAt(0) == 0) { // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
        regPone = tel
      } else {
        regPone = mobile
      }
      if (!regPone.test(value)) {
        if (regPone == tel) {
          callback(new Error('座机号不正确'))
        } else {
          callback(new Error('手机号不正确'))
        }
      } else {
        callback()
      }
    }
    return {
      imgUrl:imgUrl,
      searchForm: {
        chain_account: '',
        chain_job: null,
        chain_status:null,
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      active:1,
      accountVisible:false,
      accountVisibleTitle:'',
      accountForm:{
        id:null,
        chain_name:'',
        chain_phone:'',
        chain_account: '',
        chain_password:'',
        chain_job: null,
        chain_region_ids:[],
        chain_region_id:'',
        chain_status:null,
      },
      rules:{
        chain_name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        chain_phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        chain_account: [{ required: true, message: '请输入账号', trigger: 'blur' }],
        chain_job: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        chain_region_ids: [{ required: true, message: '请选择区域', trigger: 'blur' }],
        chain_status: [{ required: true, message: '请选择状态', trigger: 'blur' }]
      },
      chainRegionList:[]
    }
  },
  methods: {
    async ChainRegion(){
      let res=await getChainRegion_api()
      this.chainRegionList=res.data.data
    },
    //获取商品列表
    async getGoodsList(){
      let res=await getChainUserAccountList_api({
        chain_account:this.searchForm.chain_account,
        chain_job: this.searchForm.chain_job,
        chain_status:this.searchForm.chain_status,
        page:this.currentPage,
        limit:this.pageSize
      })
      const list=res.data.data.list
      this.tableData=list.map(item=>({
        ...item,
        area:item.region_array[0]?(item.region_array.map(i=>i.region_name)).join(','):''
      }))
      this.total=res.data.data.total_count
    },
    //重置
    emptySearch() {
      this.searchForm = {}
      this.currentPage=1
      this.getGoodsList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getGoodsList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
      this.getGoodsList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
      this.getGoodsList()
    },
    add(){
      this.accountVisibleTitle='新增账号设置'
      this.accountForm.id=null
      this.accountForm.chain_name=''
      this.accountForm.chain_phone=''
      this.accountForm.chain_account=''
      this.accountForm.chain_password=''
      this.accountForm.chain_job=null
      this.accountForm.chain_region_ids=[]
      this.accountForm.chain_region_id=''
      this.accountForm.chain_status=null
      this.accountVisible=true
    },
    edit(row){
      this.accountVisibleTitle='编辑账号设置'
      this.accountForm.id=row.id
      this.accountForm.chain_name=row.chain_name
      this.accountForm.chain_phone=row.chain_phone
      this.accountForm.chain_account=row.chain_account
      this.accountForm.chain_password=row.chain_password
      this.accountForm.chain_job=row.chain_job
      this.accountForm.chain_region_id=row.chain_region_id
      if(this.accountForm.chain_region_id){
        const list=this.accountForm.chain_region_id.split(',')
        this.accountForm.chain_region_ids=list.map(item=>Number(item))
      }
      this.accountForm.chain_status=row.chain_status
      this.accountVisible=true
    },
    async submitaccountForm(){
       this.$refs.accountForm.validate(async(valid) => {
        if (valid) {
          if(this.accountForm.chain_region_ids.length>0){
            const list=this.accountForm.chain_region_ids.join(',')
            this.accountForm.chain_region_id=list
          }
          if(!this.accountForm.id){
            // 新增
            let res = await createChainUserAccount_api(this.accountForm)
            if(res.data.code == 0){
              this.getGoodsList()
              this.$message({
                message: '新增成功',
                type: 'success'
              });
            }else{
              this.$message.error(res.data.msg);
            }
          }else{
            // 编辑
            let res = await editChainUserAccount_api(this.accountForm)
            if(res.data.code == 0){
              this.getGoodsList()
              this.$message({
                message: '编辑成功',
                type: 'success'
              });
            }else{
              this.$message.error(res.data.msg);
            }
          }
          this.accountVisible=false
       } else {
          return false
        }
      })
    }
  },
  created(){
    this.getGoodsList()
    this.ChainRegion()
  },
  destroyed(){
    local.remove("shopName1")
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.btn-list {
  display: flex;
  align-items: center;
}
</style>